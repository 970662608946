import { db } from './firebaseConfig';
import { collection, addDoc, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';

// Add a new document to a specific collection
export const addDocument = async (collectionName, documentData) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), documentData);
    return docRef.id;
  } catch (error) {
    console.error('Error adding document: ', error);
    throw error;
  }
};

// Fetch all documents from a specific collection
export const fetchCollection = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error fetching collection: ', error);
    throw error;
  }
};

// Fetch a specific document from a collection
export const fetchDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    throw error;
  }
};

// Update a specific document in a collection
export const updateDocument = async (collectionName, documentId, updatedData) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await updateDoc(docRef, updatedData);
    return true;
  } catch (error) {
    console.error('Error updating document: ', error);
    throw error;
  }
};

// Delete a specific document from a collection
export const deleteDocument = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    console.error('Error deleting document: ', error);
    throw error;
  }
};

