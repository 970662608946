// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import 'leaflet/dist/leaflet.css';

// import { AuthProvider } from './context/AuthContext'; // Remove this line

ReactDOM.render(
  <React.StrictMode>
    {/* Remove the AuthProvider wrapper */}
    {/* <AuthProvider> */}
      <App />
    {/* </AuthProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);
