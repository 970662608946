import React, { useState } from 'react';
import { Button, Input, message, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { fetchDocument } from '../firebase/firestoreService';
import '../styles/global.css';

const { Title, Text } = Typography;

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, passcode);
      const userData = await fetchDocument('Users', user.user.uid);

      if (userData) {
        message.success('Login successful!');
        navigate('/account', { state: { userId: user.user.uid } }); // Proceed to account page
      } else {
        throw new Error('User data not found');
      }
    } catch (error) {
      console.error('Error during login:', error);
      message.error('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div className="login-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', backgroundColor: 'var(--background-color)' }}>
      <div className="login-card" style={{ maxWidth: '400px', padding: '24px', borderRadius: '8px', backgroundColor: 'var(--card-background-color)' }}>
        <Title level={2} style={{ textAlign: 'center', color: 'var(--primary-color)' }}>Login</Title>
        <Text style={{ display: 'block', marginBottom: '16px', textAlign: 'center', color: 'var(--dark-text-color)' }}>
          Welcome back! Please login to access your account.
        </Text>
        <Input 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          style={{ marginBottom: '16px', backgroundColor: 'var(--input-background-color)', color: 'var(--input-text-color)' }} 
        />
        <Input.Password 
          placeholder="Passcode" 
          value={passcode} 
          onChange={(e) => setPasscode(e.target.value)} 
          style={{ marginBottom: '24px', backgroundColor: 'var(--input-background-color)', color: 'var(--input-text-color)' }} 
        />
        <Button 
          type="primary" 
          block 
          onClick={handleLogin} 
          className="signup-button"
        >
          Login
        </Button>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '16px' }}>
          <Text style={{ color: 'var(--link-color)', cursor: 'pointer' }} onClick={() => navigate('/signup')}>
            Sign Up
          </Text>
          <Text style={{ color: 'var(--link-color)', cursor: 'pointer' }} onClick={() => navigate(-1)}>
            Back
          </Text>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
