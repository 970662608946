import React, { useState, useEffect } from 'react';
import { Menu, Drawer, Switch } from 'antd';
import { MenuOutlined, MoonOutlined, SunOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    document.body.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const toggleTheme = (checked) => {
    setIsDarkMode(checked);
  };

  return (
    <div className="header">
      <div className="header-logo">
        <img src={logo} alt="Luxe Car Logo" className="logo-image" />
        <span className="logo-text">Luxe Car</span>
      </div>
      <div className="header-right">
        <Switch 
          checked={isDarkMode}
          onChange={toggleTheme}
          checkedChildren={<MoonOutlined />}
          unCheckedChildren={<SunOutlined />}
        />
        <MenuOutlined className="menu-icon" onClick={showDrawer} />
      </div>
      
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        width="250"
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <Menu mode="vertical" className="header-menu">
          <Menu.Item key="home">
            <Link to="/" onClick={closeDrawer}>Home</Link>
          </Menu.Item>
          <Menu.Item key="browse-vehicles">
            <Link to="/browse-vehicles" onClick={closeDrawer}>Browse Vehicles</Link>
          </Menu.Item>
          <Menu.Item key="fare-calculator">
            <Link to="/fare-calculator" onClick={closeDrawer}>Fare Calculator</Link>
          </Menu.Item>
          <Menu.Item key="contact-us">
            <Link to="/contact-us" onClick={closeDrawer}>Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="bookings">
            <Link to="/booking" onClick={closeDrawer}>Bookings</Link>
          </Menu.Item>
          <Menu.Item key="account">
            <Link to="/account" onClick={closeDrawer}>Account</Link>
          </Menu.Item>
          <Menu.Item key="signup">
            <Link to="/signup" onClick={closeDrawer}>Signup</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
};

export default Header;
