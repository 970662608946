import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined, CompassOutlined, BookOutlined, UserOutlined } from '@ant-design/icons';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-item">
        <Link to="/">
          <HomeOutlined className="footer-icon" />        </Link>

          <span>Home</span>
      </div>
      <div className="footer-item">
        <Link to="/browse-vehicles">
          <CompassOutlined className="footer-icon" />        </Link>

          <span>Explore</span>
      </div>
      <div className="footer-item">
        <Link to="/booking">
          <BookOutlined className="footer-icon" />        </Link>

          <span>Bookings</span>
      </div>
      <div className="footer-item">
        <Link to="/account">
          <UserOutlined className="footer-icon" />        </Link>
          <span>Profile</span>
      </div>
    </div>
  );
};

export default Footer;
