import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import './styles/global.css';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginComponent from './components/LoginComponent';

const HomePage = lazy(() => import('./pages/HomePage'));
const BookingPage = lazy(() => import('./pages/BookingPage'));
const BookingHistoryPage = lazy(() => import('./pages/BookingHistoryPage'));
const SignupPage = lazy(() => import('./pages/SignupPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const AdminDashboardPage = lazy(() => import('./pages/AdminDashboardPage'));
const DriverDashboardPage = lazy(() => import('./pages/DriverDashboardPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const VehicleBrowsePage = lazy(() => import('./pages/VehicleBrowsePage'));
const FareCalculatorPage = lazy(() => import('./pages/FareCalculatorPage'));
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const LoginPage = lazy(() => import('./components/LoginComponent')); // Import the LoginPage component
const SingleCarView = lazy(() => import('./components/SingleCarView')); 
const SignupComponent = lazy(() => import('./components/SignupComponent')); 

const { Content } = Layout;

function App() {
  return (
    <Router>
      <Layout>
        <Header />

        <Content style={{ 
          padding: '0px', 
          backgroundColor: 'var(--background-color)',
          marginTop: '60px',
          marginBottom: '60px',
          minHeight: 'calc(100vh - 120px)' }}>
          <Suspense fallback={<Spin size="large" style={{ display: 'block', margin: '20% auto' }} />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/browse-vehicles" element={<VehicleBrowsePage />} />
              <Route path="/fare-calculator" element={<FareCalculatorPage />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/booking" element={<BookingPage />} />
              <Route path="/history" element={<BookingHistoryPage />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/admin" element={<AdminDashboardPage />} />
              <Route path="/driver" element={<DriverDashboardPage />} />
              <Route path="/signup" element={<SignupComponent />} /> 
              <Route path="/login" element={<LoginComponent />} /> {/* New route for LoginPage */}
              <Route path="/car/:carId" element={<SingleCarView />} />
              <Route path="/404" element={<NotFoundPage />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
          </Suspense>
        </Content>

        <Footer />
      </Layout>
    </Router>
  );
}

export default App;
