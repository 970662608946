import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA1EQkMI3JkTuYOK15b-nOjhh2tJHSLPRk',
  authDomain: 'vistalimo-abe6d.firebaseapp.com',
  projectId: 'vistalimo-abe6d',
  storageBucket: 'vistalimo-abe6d.appspot.com',
  messagingSenderId: '826922028737',
  appId: '1:826922028737:web:e23f618f67ded825810e40'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Export the initialized services and necessary methods for use in other parts of the app
export { db, auth, signInWithEmailAndPassword };
